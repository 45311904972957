import actions from "./actions"
import * as types from "../../types"
// import _ from "lodash"
const user = {
    state: {
        user: {},
        api_token: ""
    },

    mutations: {
        [types.STORE_SET_TOKEN]: (state, api_token) => {
            Object.assign(state, { api_token })
        },
        [types.STORE_SET_USER]: (state, user) => {
            let levels = user && user.levels ? user.levels : state.user ? state.user.levels : []
            if (user) {
                user.levels = levels
            }
            // user = _.merge(user, state.user)
            Object.assign(state, { user })
        }
    },

    actions,

    getters: {
        getUser: state => state.user
    }
}

export default user
