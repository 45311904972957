export const reviewMap = {
    path: "/review-map",
    name: "Review Map",
    meta: { requiresAuth: true, type: "review_maps" },
    component: () => import("@/views/order/buff/review/review-order.vue")
}

export const reviewApp = {
    path: "/review-app",
    name: "Review App",
    meta: { requiresAuth: true, type: "review_apps" },
    component: () => import("@/views/order/buff/review/review-order.vue")
}
