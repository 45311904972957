export const like = {
    path: "/tiktok-like",
    name: "Buff Like Tiktok",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/tiktok/tiktok-order.vue")
}
export const follow = {
    path: "/tiktok-follow",
    name: "Buff Follow Tiktok",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/tiktok/tiktok-order.vue")
}
export const comment = {
    path: "/tiktok-comment",
    name: "Buff Comment Tiktok",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/tiktok/tiktok-order.vue")
}
export const view = {
    path: "/tiktok-view",
    name: "Buff View Video",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/view/view-order.vue")
}
export const favorites = {
    path: "/tiktok-favorites",
    name: "Buff Favorite Tiktok",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/tiktok/tiktok-order.vue")
}
export const comboLikeSup = {
    path: "/tiktok-combo-like-sub",
    name: "Buff Combo Like Sub Tiktok",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/tiktok/tiktok-order.vue")
}
