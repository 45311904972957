/** When your routing table is too long, you can split it into small modules* */
import store from "@/store"
import * as facebook from "./order/facebook"
import * as instagram from "./order/instagram"
import * as youtube from "./order/youtube"
import * as review from "./order/review"
import * as other from "./order/other"
import * as tiktok from "./order/tiktok"
import * as admin from "./admin"
import * as user from "./user"
import * as agency from "./agency"
import * as agencyLv2 from "./agency/agency-lv2"
import * as shopee from "./order/shopee"
import * as traffic from "./order/traffic"
import * as facebookSv3 from "./order/facebook-sv3"
import * as twitter from "./order/twitter"
import * as lazada from "./order/lazada"

const dashboardRouter = {
    path: "/",
    component: () => import("@/layout/dashboard.vue"),
    redirect: { name: "Home" },
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
        let isLogin = !!store.state.user.api_token
        let isLandingPage = !!store.state.site.site.landing_page
        let isRequiresAuth = to.matched.some(record => record.meta.requiresAuth)
        let isNewSite = store.state.site && store.state.site.site ? true : false
        if (!isLogin && to.path == "/home" && isLandingPage) {
            window.location.href = window.location.origin + "/landing-page.html"
        } else {
            if (!isNewSite && to.path === "/api-key") {
                return next({
                    path: "/"
                })
            }
            if (isLogin && to.path === "/login") {
                return next("/")
            }
            if (isRequiresAuth && isLogin) {
                let hasUserName = !(
                    store.state.user.username &&
                    store.state.user.avatar_id &&
                    store.state.user.username === store.state.user.avatar_id
                )
                if (!hasUserName && to.name !== "UpdateInfoAccount") {
                    return next({
                        path: "/update-info"
                    })
                }
            } else if (isRequiresAuth && !isLogin && to.path !== "/login") {
                return next("/login")
            }
            next()
        }
    },
    children: [
        {
            path: "/home",
            name: "Home",
            meta: { requiresAuth: true },
            component: () => import("@/views/home/home.vue")
        },
        admin.clients,
        admin.notification,
        admin.refund,
        admin.site,
        admin.statistics,
        admin.landingpage,
        admin.menu,
        admin.paymentCard,
        user.payment,
        user.profile,
        user.notification,
        user.report,
        user.errorOrderReport,
        facebook.like,
        facebook.commment,
        facebook.like_comment,
        facebook.share,
        facebook.follow,
        facebook.like_page,
        facebook.like_corona,
        facebook.follow_corona,
        facebook.like_page_corona,
        facebook.share_corona,
        facebook.join_group_corona,
        facebook.review,
        facebook.view,
        facebookSv3.like,
        facebookSv3.follow,
        facebookSv3.likePage,
        instagram.like,
        instagram.follow,
        instagram.comment,
        tiktok.like,
        tiktok.follow,
        tiktok.comment,
        tiktok.view,
        tiktok.favorites,
        tiktok.comboLikeSup,
        youtube.view,
        youtube.comment,
        youtube.sub,
        shopee.follow,
        shopee.like,
        other.spamSMS,
        other.converPhoneToUid,
        other.converUidToPhone,
        other.outSiteV2,
        facebook.vip_clone,
        facebook.vip_like_sv3,
        facebook.vip_sl,
        facebook.vip_month,
        facebook.vip_reaction,
        facebook.vip_comment,
        facebook.mat_live_v1,
        facebook.mat_live_v2,
        facebook.mat_view_v2,
        agency.agencyManager,
        agency.agencySetings,
        agencyLv2.clients,
        agencyLv2.reports,
        agencyLv2.siteAPI,
        agencyLv2.siteLayout,
        agencyLv2.siteNotificationOrder,
        agencyLv2.siteNotification,
        agencyLv2.sitePayment,
        agencyLv2.sitePrices,
        agencyLv2.siteSupport,
        agencyLv2.siteWebsite,
        twitter.like,
        twitter.follow,
        twitter.comment,
        traffic.google,
        traffic.backlink,
        traffic.redirect,
        review.reviewMap,
        review.reviewApp,
        lazada.like,
        lazada.follow
    ]
}

export default dashboardRouter
