export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const REGISTER = "REGISTER"
export const GET_SITE = "GET_SITE"
export const GET_SITE_AGENCY_INFO = "GET_SITE_AGENCY_INFO"

//APP
export const APP_LOADING = "APP_LOADING"
export const APP_RELOAD = "APP_RELOAD"
export const APP_IS_MOBILE = "APP_IS_MOBILE"
export const APP_IS_MIMI_SIDEBAR = "APP_IS_MIMI_SIDEBAR"
export const APP_BEFORE_PATH = "APP_BEFORE_PATH"
export const APP_LAST_PHONE_POPUP = "APP_LAST_PHONE_POPUP"

//tools
export const GET_TOOLS = "GET_TOOLS"

//auth
export const STORE_SET_TOKEN = "STORE_SET_TOKEN"
export const STORE_SET_USER = "STORE_SET_USER"
export const STORE_SET_LIST_TOOLS = "STORE_SET_LIST_TOOLS"
export const STORE_SET_CHOOSE_TOOLS = "STORE_SET_CHOOSE_TOOLS"
export const STORE_SET_SITE_ID = "STORE_SET_SITE_ID"
export const GET_MENU_OUT_SITE = "GET_MENU_OUT_SITE"
export const STORE_SET_MENU_OUT_SITE = "STORE_SET_MENU_OUT_SITE"
export const STORE_SET_MENU_OUT_SITE_INDEX = "STORE_SET_MENU_OUT_SITE_INDEX"
export const STORE_SET_AGENCY_INFO = "STORE_SET_AGENCY_INFO"
export const STORE_SET_AGENCY_SITE = "STORE_SET_AGENCY_SITE"

// user
export const USER_GET_INFO = "USER_GET_INFO"
export const USER_UPDATE = "USER_UPDATE"

//facebook
export const FACEBOOK_ORDER = "FACEBOOK_ORDER"

//instagram
export const INSTAGRAM_ORDER = "INSTAGRAM_ORDER"

// refund notification
export const SET_POPUP_STATUS = "SET_POPUP_STATUS"
