export const follow = {
    path: "/shopee-follow",
    name: "Buff Follow Shopee",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/shopee/shopee-order.vue")
}
export const like = {
    path: "/shopee-like",
    name: "Buff Like Shopee",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/shopee/shopee-order.vue")
}
