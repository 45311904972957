import * as types from "../../types"
import actions from "./actions"
const user = {
    agency: {
        agency_token: "",
        agency_domain: "false",
        site: null
    },

    mutations: {
        [types.STORE_SET_AGENCY_INFO]: (state, data) => {
            Object.assign(state, { agency_domain: data.agency_domain })
            Object.assign(state, { agency_token: data.agency_token })
        },
        [types.STORE_SET_AGENCY_SITE]: (state, data) => {
            Object.assign(state, { site: data })
        }
    },
    actions
}

export default user
