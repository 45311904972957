import { request } from "../utils/request"
import store from "@/store"

export function getTools() {
    return request({
        url: "api/tools?site_id=" + store.state.site.site.id,
        method: "get"
    })
}
export function getToolsSv4() {
    return request({
        url: "api/tools?site_id=" + store.state.site.site.id,
        // url: "api/tools?site_id=999",
        method: "get"
    })
}
