export const payment = {
    path: "/user-payment",
    name: "Payment",
    meta: { requiresAuth: true },
    component: () => import("../../views/user/user-payment")
}
export const profile = {
    path: "/user-profile",
    name: "Profile",
    meta: { requiresAuth: true },
    component: () => import("../../views/user/user-profile")
}
export const notification = {
    path: "/user-notification",
    name: "Notification",
    meta: { requiresAuth: true },
    component: () => import("../../views/user/user-notification")
}
export const report = {
    path: "/report",
    name: "Report",
    meta: { requiresAuth: true },
    component: () => import("../../views/user/user-report")
}
export const errorOrderReport = {
    path: "/error-order-report",
    name: "OrderOrderReport",
    meta: { requiresAuth: true },
    component: () => import("../../views/user/user-error-order-report")
}
