export const clients = {
    path: "/agency-manager/clients",
    name: "Quản Lý Người Dùng",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/client/client-manager.vue")
}
export const sitePrices = {
    path: "/agency-manager/prices",
    name: "Cài đặt Bảng Giá",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/prices/prices-manager.vue")
}

export const reports = {
    path: "/agency-manager/report",
    name: "Nhật Ký Hoạt Động",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/report/report-manager.vue")
}
export const siteAPI = {
    path: "/agency-manager/api",
    name: "Cập nhật API Key",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/site/site-api-key.vue")
}

export const siteWebsite = {
    path: "/agency-manager/website",
    name: "Cài đặt Website",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/site/site-website.vue")
}
export const siteLayout = {
    path: "/agency-manager/layout",
    name: "Cài đặt giao diện",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/site/site-layout.vue")
}
export const sitePayment = {
    path: "/agency-manager/payment",
    name: "Cài đặt thanh toán",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/site/site-payment.vue")
}
export const siteSupport = {
    path: "/agency-manager/support",
    name: "Cài đặt hỗ trợ",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/site/site-support.vue")
}
export const siteNotificationOrder = {
    path: "/agency-manager/notification-order",
    name: "Cài đặt thông báo tạo đơn",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/site/site-notification-order.vue")
}
export const siteNotification = {
    path: "/agency-manager/notification",
    name: "Cài đặt thông báo",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/site/site-notification.vue")
}
