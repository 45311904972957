import { login } from "../../../api/auth"
import { getInfo } from "../../../api/user"
import * as types from "../../types"
import router from "@/router"
import { GET_TOOLS, USER_GET_INFO } from "@/store/types"
import store from "@/store"

export default {
    async [types.LOGIN]({ commit }, payload) {
        try {
            const data = await login(payload.params)
            if (data.success) {
                commit(types.STORE_SET_TOKEN, data.data.token)
                commit(types.STORE_SET_USER, data.data.data)
                store.dispatch(GET_TOOLS)
                store.dispatch(USER_GET_INFO)
            }
            router.push("/")
            return data
        } catch (e) {
            throw e
        }
    },
    [types.LOGOUT]({ commit }) {
        commit(types.STORE_SET_TOKEN, "")
        commit(types.STORE_SET_USER, {})
        router.push("/login")
    },
    async [types.USER_GET_INFO]({ commit }) {
        try {
            const data = await getInfo()
            if (data.data) {
                commit(types.STORE_SET_USER, data.data)
            }
            return data
        } catch (e) {
            throw e
        }
    }
}
