export const loginRouter = {
    path: "/login",
    component: () => import("../../views/auth/auth-login.vue")
}
export const registerRouter = {
    path: "/register",
    component: () => import("../../views/auth/auth-register")
}
export const fogetPasswordRouter = {
    path: "/forget-password",
    component: () => import("../../views/auth/auth-forget-password")
}
export const activeApiKey = {
    path: "/api-key",
    component: () => import("../../views/auth/auth-api-key")
}
export const site503 = {
    path: "/503",
    name: "503",
    meta: { requiresAuth: true },
    component: () => import("../../layout/503.vue")
}
