import actions from "./actions"
import * as types from "../../types"

const user = {
    state: {
        site: "",
        menus: [],
        menuIndex: null
    },

    mutations: {
        [types.STORE_SET_SITE_ID]: (state, site) => {
            Object.assign(state, { site })
        },
        [types.STORE_SET_MENU_OUT_SITE]: (state, menus) => {
            Object.assign(state, { menus })
        },
        [types.STORE_SET_MENU_OUT_SITE_INDEX]: (state, menuIndex) => {
            Object.assign(state, { menuIndex })
        }
    },

    actions
}

export default user
