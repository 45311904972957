import { request } from "../utils/request"

export function getInfo() {
    return request({
        url: "api/me",
        method: "get"
    })
}
export function userGetNotify() {
    return request({
        url: "api/notification?limit=50",
        method: "get"
    })
}
export function getToolsLogs(limit) {
    return request({
        url: "api/tool-logs?limit=" + limit,
        method: "get"
    })
}
export function getToolsLogsByQuery(queries) {
    return request({
        url: "api/tool-logs" + queries,
        method: "get"
    })
}
export function updateNotify(id) {
    let data = { id: id }
    return request({
        url: "api/notification/update/" + id,
        method: "post",
        data
    })
}
export function verifyApiKey(data) {
    return request({
        url: "api/verify-site",
        method: "post",
        data
    })
}
export function userUpdateInfo(id, data) {
    return request({
        url: "api/users/" + id,
        method: "post",
        data
    })
}
export function getErrorPackageNames(limit = 100) {
    return request({
        url: "admin/logs/error?limit=" + limit,
        method: "get"
    })
}
export function getErrorPackageNamesByQuery(queries) {
    return request({
        url: "admin/logs/error" + queries,
        method: "get"
    })
}
