import { getSiteConfig } from "../../../api/admin-agency-lv2"
import * as types from "../../types"
export default {
    async [types.GET_SITE_AGENCY_INFO]({ commit }, payload) {
        try {
            const data = await getSiteConfig(payload)
            if (data.data && data.success) {
                commit(types.STORE_SET_AGENCY_SITE, data.data)
            }
            return data
        } catch (e) {
            throw e
        }
    }
}
