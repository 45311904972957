import axios from "axios"
import store from "@/store"
import router from "@/router"
import { API_URL, API_URL_AGRNCY_LV2, API_SUPPORT } from "../config"
import { LOGOUT, APP_LOADING } from "../store/types"

export const request = async function(postData) {
    store.commit(APP_LOADING, postData && postData.url && postData.url.includes("/site/get-config") ? false : true)
    return axios({
        url: API_URL + postData.url,
        method: postData.method,
        data: postData.data ? postData.data : null,
        headers: {
            Authorization: "Bearer " + store.state.user.api_token
        }
    })
        .then(result => {
            store.commit(APP_LOADING, false)
            return result.data
        })
        .catch(error => {
            store.commit(APP_LOADING, false)
            if (!error.response) {
                router.push("/503")
            }
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        store.dispatch(LOGOUT)
                        router.currentRoute.path !== "login" &&
                            router.replace({
                                path: "login",
                                query: { redirect: router.currentRoute.path }
                            })
                        break
                    case 503:
                        router.push("503")
                        break
                }
                return error.response.data
            }
        })
}
export const requestLv2 = async function(postData) {
    store.commit(APP_LOADING, true)
    return axios({
        url: API_URL_AGRNCY_LV2 + postData.url,
        method: postData.method,
        data: postData.data ? postData.data : null,
        headers: {
            Authorization: "Bearer " + store.state.agency.agency_token
        }
    })
        .then(result => {
            store.commit(APP_LOADING, false)
            return result.data
        })
        .catch(error => {
            store.commit(APP_LOADING, false)
            if (!error.response) {
                router.push("/503")
            }
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        store.dispatch(LOGOUT)
                        router.currentRoute.path !== "login" &&
                            router.replace({
                                path: "login",
                                query: { redirect: router.currentRoute.path }
                            })
                        break
                    case 503:
                        router.push("503")
                        break
                }
                return error.response.data
            }
        })
}
export const requestLv3 = async function(postData) {
    store.commit(APP_LOADING, true)
    return axios({
        url: API_URL + postData.url,
        method: postData.method,
        data: postData.data ? postData.data : null,
        headers: {
            Authorization: "Bearer " + store.state.agency.agency_token
        }
    })
        .then(result => {
            store.commit(APP_LOADING, false)
            return result.data
        })
        .catch(error => {
            store.commit(APP_LOADING, false)
            if (!error.response) {
                window.location.href = window.location.origin + "/login"
            }
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        store.dispatch(LOGOUT)
                        router.currentRoute.path !== "login" &&
                            router.replace({
                                path: "login",
                                query: { redirect: router.currentRoute.path }
                            })
                        break
                    case 503:
                        router.push("503")
                        break
                }
                return error.response.data
            }
        })
}
export const requestSupport = async function(postData) {
    store.commit(APP_LOADING, true)
    return axios({
        url: API_SUPPORT + postData.url,
        method: postData.method,
        data: postData.data ? postData.data : null,
        headers: {
            Authorization: "Bearer " + store.state.user.api_token
        }
    })
        .then(result => {
            store.commit(APP_LOADING, false)
            return result.data
        })
        .catch(error => {
            store.commit(APP_LOADING, false)
            console.log("request error", error)
            if (!error.response) {
                router.push("/503")
            }
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        store.dispatch(LOGOUT)
                        router.currentRoute.path !== "login" &&
                            router.replace({
                                path: "login",
                                query: { redirect: router.currentRoute.path }
                            })
                        break
                    case 503:
                        router.push("503")
                        break
                }
                return error.response.data
            }
        })
}
