export const like = {
    path: "/lazada-like",
    name: "Buff Like Lazada",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/lazada/lazada-order.vue")
}
export const follow = {
    path: "/lazada-follow",
    name: "Buff Follow Lazada",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/lazada/lazada-order.vue")
}
