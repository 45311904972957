export const clients = {
    path: "/clients",
    name: "ClientManager",
    meta: { requiresAuth: true },
    component: () => import("@/views/admin/client/client-manager.vue")
}
export const notification = {
    path: "/notification",
    name: "Notification",
    meta: { requiresAuth: true },
    component: () => import("@/views/admin/notification/notification-setting.vue")
}
export const refund = {
    path: "/refund",
    name: "Refund",
    meta: { requiresAuth: true },
    component: () => import("@/views/admin/refund/refund-manager.vue")
}
export const site = {
    path: "/site-config",
    name: "Site",
    meta: { requiresAuth: true },
    component: () => import("@/views/admin/site/site-manager.vue")
}
export const statistics = {
    path: "/statistics",
    name: "Statistics",
    meta: { requiresAuth: true },
    component: () => import("@/views/admin/statistics/statistics-manager.vue")
}
export const landingpage = {
    path: "/landing-page",
    name: "Landingpage",
    meta: { requiresAuth: true },
    component: () => import("@/views/admin/landing-page/landing-page.vue")
}
export const menu = {
    path: "/site-menu",
    name: "Menu",
    meta: { requiresAuth: true },
    component: () => import("@/views/admin/menus/menus-manager.vue")
}
export const paymentCard = {
    path: "/site-payment-card",
    name: "PaymentCard",
    meta: { requiresAuth: true },
    component: () => import("@/views/admin/payment/payment-card.vue")
}
