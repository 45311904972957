export const agencyManager = {
    path: "/agency-manager",
    name: "Cài Đặt Đại Lý",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/agency-manager.vue")
}
export const agencySetings = {
    path: "/agency-settings",
    name: "Cài Đặt Trang",
    meta: { requiresAuth: true },
    component: () => import("@/views/agency/agency-settings.vue")
}
