import * as types from "../../types"
import moment from "moment"

const user = {
    app: {
        isLoading: 0,
        isMobile: false,
        reload: false,
        isMiniSidebar: false,
        beforePath: null,
        lastPhonePopup: null
    },

    mutations: {
        [types.APP_LOADING]: (state, loading) => {
            let isLoading =
                loading == 0 ? 0 : loading ? (state.isLoading ? state.isLoading + 1 : 1) : state.isLoading - 1
            isLoading = isLoading < 0 ? 0 : isLoading
            Object.assign(state, { isLoading })
        },
        [types.APP_RELOAD]: (state, reload) => {
            reload = !state.reload
            Object.assign(state, { reload })
        },
        [types.APP_IS_MOBILE]: (state, isMobile) => {
            Object.assign(state, { isMobile })
        },
        [types.APP_BEFORE_PATH]: (state, beforePath) => {
            console.log(beforePath)
            Object.assign(state, { beforePath })
        },
        [types.APP_IS_MIMI_SIDEBAR]: (state, isMiniSidebar) => {
            isMiniSidebar = !state.isMiniSidebar
            Object.assign(state, { isMiniSidebar })
        },
        [types.APP_LAST_PHONE_POPUP]: (state, lastPhonePopup) => {
            if (moment(lastPhonePopup).isValid()) Object.assign(state, { lastPhonePopup })
        }
    }
}

export default user
