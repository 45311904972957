import * as types from "../../types"

const refund_popup = {
    state: {
        closed: false
    },

    mutations: {
        [types.SET_POPUP_STATUS]: (state, closed) => {
            Object.assign(state, { closed })
        }
    },

    getters: {
        getPopupStatus: state => state.closed
    }
}

export default refund_popup
