export const google = {
    path: "/traffics",
    name: "Traffic Google User Search",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/traffic/traffic-order.vue")
}
export const backlink = {
    path: "/traffic-backlinks",
    name: "Traffic BackLink User",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/traffic/traffic-order.vue")
}

export const redirect = {
    path: "/traffic-redirect",
    name: "Traffic Redirect",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/traffic/traffic-order.vue")
}
