import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
//modules
import app from "./modules/app"
import user from "./modules/user"
import tools from "./modules/tools"
import site from "./modules/site"
import agency from "./modules/agency"
import refund_popup from "./modules/refund-popup"

Vue.use(Vuex)
Vue.config.devtools = true
export default new Vuex.Store({
    modules: {
        app,
        user,
        tools,
        site,
        agency,
        refund_popup
    },
    plugins: [
        createPersistedState({
            key: "AGENCY",
            reducer(val) {
                if ((val && val.tools && !val.tools.tools) || (val && val.site && !val.site.site)) {
                    return {}
                }
                return val
            }
        })
    ]
})
