import { requestLv2 } from "../utils/request"

export function getSiteConfig(siteHost) {
    return requestLv2({
        url: "/site?domain=" + siteHost,
        method: "get"
    })
}

export function getAllUser(limit, param) {
    return requestLv2({
        url: "/admin/users?limit=" + limit + param,
        method: "get"
    })
}
export function getToolsLogs(limit) {
    return requestLv2({
        url: "/admin/logs?limit=" + limit,
        method: "get"
    })
}
export function getLogsOrder(limit) {
    return requestLv2({
        url: "/admin/logs?limit=" + limit,
        method: "get"
    })
}
export function getListLevels() {
    return requestLv2({
        url: "/admin/level",
        method: "get"
    })
}
export function updateLevelAdmin(data) {
    return requestLv2({
        url: "/admin/level/update",
        method: "post",
        data
    })
}
export function updateInfoUser(data) {
    return requestLv2({
        url: "/admin/users/" + data.id,
        method: "post",
        data
    })
}
export function updateUserLevel(data) {
    return requestLv2({
        url: "/admin/users/update",
        method: "post",
        data
    })
}
export function updateUser(id, data) {
    return requestLv2({
        url: "/admin/users/update",
        method: "post",
        data
    })
}
export function updateAPI(data) {
    return requestLv2({
        url: "/admin/site/update-api-key",
        method: "post",
        data
    })
}
export function deleteUser(id) {
    return requestLv2({
        url: "/admin/users/remove/" + id,
        method: "get"
    })
}
export function updateLevelInfo(id, data) {
    return requestLv2({
        url: "/admin/levels/update/" + id,
        method: "post",
        data
    })
}
export function getLogsAllUsers(limit = null, username = null) {
    return requestLv2({
        url: "/admin/logs?sort_by=desc" + (limit ? "&limit=" + limit : "") + (username ? "&username=" + username : ""),
        method: "get"
    })
}
export function getListRefund(limit = 100, param) {
    return requestLv2({
        url: "/admin/refund" + (limit ? "?limit=" + limit : "") + param,
        method: "get"
    })
}
export function getDetailRefund(param) {
    return requestLv2({
        url: "api/advertising/list" + param,
        method: "get"
    })
}
export function getToolAdmin(level_id) {
    return requestLv2({
        url: "/admin/site/prices" + (level_id ? "?level_id=" + level_id : ""),
        method: "get"
    })
}
export function updateRefund(id, data) {
    return requestLv2({
        url: "/admin/refund/update/" + id,
        method: "post",
        data
    })
}
export function confirmRefund(id) {
    let data = { id }
    return requestLv2({
        url: "/admin/refund/confirm/" + id,
        method: "post",
        data
    })
}
export function updateSiteInfo(data) {
    return requestLv2({
        url: "/admin/site/update",
        method: "post",
        data
    })
}
export function priceUpdate(data) {
    return requestLv2({
        url: "/admin/site/update-prices",
        method: "post",
        data
    })
}
export function priceReset(data) {
    return requestLv2({
        url: "/admin/sites/reset-price",
        method: "post",
        data
    })
}
export function hideTool(id) {
    return requestLv2({
        url: "/admin/site/hidden-tool/" + id,
        method: "get"
    })
}
export function showTool(data) {
    return requestLv2({
        url: "/admin/sites/open-tool",
        method: "post",
        data
    })
}
export function getStatisticsAll() {
    return requestLv2({
        url: "/admin/statistical",
        method: "get"
    })
}
export function getStatisticsRevenue(dateFrom, dateTo) {
    return requestLv2({
        url: "/admin/statistical/revenue?date_from=" + dateFrom + "&date_to=" + dateTo,
        method: "get"
    })
}
export function getStatisticsTools(dateFrom, dateTo) {
    return requestLv2({
        url: "/admin/statistical/by-tool?date_from=" + dateFrom + "&date_to=" + dateTo,
        method: "get"
    })
}
export function getStatisticsUser(dateFrom, dateTo, type, limit) {
    return requestLv2({
        url:
            "/admin/statistical/customer?date_from=" +
            dateFrom +
            "&date_to=" +
            dateTo +
            "&type=" +
            type +
            "&limit=" +
            limit,
        method: "get"
    })
}
