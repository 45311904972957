import { request, requestLv3 } from "../utils/request"

export function getSiteConfig(siteHost) {
    siteHost = "http://" + siteHost
    siteHost = siteHost.replace("http://www.", "http://")
    return request({
        url: "api/site/get-config?domain=" + siteHost,
        method: "get"
    })
}
export function getInfoLandingPage(data) {
    return requestLv3({
        url: "api/landing-page",
        method: "post",
        data
    })
}
export function getListMenus() {
    return request({
        url: "api/out-site-v2",
        method: "get"
    })
}
