export const like = {
    path: "/facebook-like-sv3",
    name: "Buff Like Bài Viết Giá Rẻ",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook-sv3/facebook-order.vue")
}

export const follow = {
    path: "/facebook-follow-sv3",
    name: "Buff Follow Giá Rẻ (tăng theo dõi)",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook-sv3/facebook-order.vue")
}

export const likePage = {
    path: "/facebook-like-page-sv3",
    name: "Buff Like Page Giá Rẻ",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook-sv3/facebook-order.vue")
}
