export const like = {
    path: "/twitter-like",
    name: "Buff Like Twitter",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/twitter/twitter-order.vue")
}
export const follow = {
    path: "/twitter-follow",
    name: "Buff Follow Twitter",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/twitter/twitter-order.vue")
}
export const comment = {
    path: "/twitter-comment",
    name: "Buff Comment Video",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/twitter/twitter-order.vue")
}
