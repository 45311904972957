// export const view = {
//     path: "/youtube-view",
//     name: "Buff View Video",
//     meta: { requiresAuth: true },
//     component: () => import("@/views/order/buff/youtube/youtube-order.vue")
// }
export const sub = {
    path: "/youtube-sub",
    name: "Buff Sub Channel",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/youtube/youtube-order.vue")
}
export const comment = {
    path: "/youtube-comment",
    name: "Buff Comment Video",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/youtube/youtube-order.vue")
}
export const view = {
    path: "/youtube-view",
    name: "Buff View Video",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/view/view-order.vue")
}
