export const spamSMS = {
    path: "/spam-sms",
    name: "Auto Spam SMS",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/other/spam-sms-order.vue")
}
export const converUidToPhone = {
    path: "/uid-to-phone",
    name: "Tìm SĐT bằng Facebook",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/other/convert-uid-to-phone.vue")
}
export const converPhoneToUid = {
    path: "/phone-to-uid",
    name: "Tìm Facebook bằng SĐT",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/other/convert-phone-to-uid.vue")
}
export const outSiteV2 = {
    path: "/out-site-v2",
    name: "Dịch Vụ Khác",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/out-site-v2/out-site-v2-order.vue")
}
