export const like = {
    path: "/instagram-like",
    name: "Buff Like Instagram",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/instagram/instagram-order.vue")
}
export const follow = {
    path: "/instagram-follow",
    name: "Buff Follow Instagram",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/instagram/instagram-order.vue")
}
export const comment = {
    path: "/instagram-comment",
    name: "Buff Comment Instagram",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/instagram/instagram-order.vue")
}
