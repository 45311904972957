export const like = {
    path: "/facebook-like",
    name: "Buff Like Bài Viết",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook/facebook-order.vue")
}
export const like_comment = {
    path: "/facebook-like-comment",
    name: "Buff Like Comment",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook/facebook-order.vue")
}
export const commment = {
    path: "/facebook-comment",
    name: "Buff Comment Bài Viết",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook/facebook-order.vue")
}
export const share = {
    path: "/facebook-share",
    name: "Buff Share bài viết",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook/facebook-order.vue")
}
export const follow = {
    path: "/facebook-follow",
    name: "Buff Follow (Tăng Theo Dõi)",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook/facebook-order.vue")
}
export const like_page = {
    path: "/facebook-like-page",
    name: "Buff Like Fanpage Order",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook/facebook-order.vue")
}
export const like_corona = {
    path: "/facebook-like-corona",
    name: "Buff Like Corona",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook-corona/facebook-order.vue")
}
export const follow_corona = {
    path: "/facebook-follow-corona",
    name: "Buff Follow Corona",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook-corona/facebook-order.vue")
}
export const like_page_corona = {
    path: "/facebook-like-page-corona",
    name: "Buff Like Corona",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook-corona/facebook-order.vue")
}
export const share_corona = {
    path: "/facebook-share-corona",
    name: "Buff Share Corona",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook-corona/facebook-order.vue")
}
export const join_group_corona = {
    path: "/facebook-join-group-corona",
    name: "Buff Join Group Corona",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook-corona/facebook-order.vue")
}
export const review = {
    path: "/facebook-review",
    name: "Buff Review (Tăng Đánh Giá Fanpage)",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook/facebook-order.vue")
}
export const vip_clone = {
    path: "/vip-like-clone",
    name: "Vip Like Clone Giá Rẻ",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/vip/facebook/facebook-clone.vue")
}
export const vip_like_sv3 = {
    path: "/vip_like_sv3",
    name: "Vip Like SV3",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/vip/facebook/facebook-like-sv3.vue")
}
export const vip_month = {
    path: "/vip-like-month",
    name: "Vip Like Bài Viết Tháng",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/vip/facebook/facebook-like.vue")
}
export const vip_sl = {
    path: "/vip-like-sl",
    name: "Vip Like Số Lượng Bài Viết",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/vip/facebook/facebook-like.vue")
}
export const vip_reaction = {
    path: "/vip-like-reaction",
    name: "Vip Like Cảm Xúc",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/vip/facebook/facebook-reaction.vue")
}
export const vip_comment = {
    path: "/vip-comment",
    name: "Vip Comment Bài Viết",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/vip/facebook/facebook-comment.vue")
}
export const mat_live_v1 = {
    path: "/eye-livestream-v1",
    name: "Buff Mắt Live Stream v1",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/livestream v1/livestream-order.vue")
}
export const mat_live_v2 = {
    path: "/eye-livestream-v2",
    name: "Buff Mắt Live Stream v2",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/livestream v2/livestream-order.vue")
}
export const mat_view_v2 = {
    path: "/eye-view-v2",
    name: "Buff View v2",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/buff/facebook/facebook-order.vue")
}
export const view = {
    path: "/facebook-view",
    name: "Buff View Video",
    meta: { requiresAuth: true },
    component: () => import("@/views/order/view/view-order.vue")
}
