import actions from "./actions"
import * as types from "../../types"

const tools = {
    state: {
        tools: [],
        chosenTool: {}
    },
    mutations: {
        [types.STORE_SET_LIST_TOOLS]: (state, tools) => {
            state.tools = tools
        },
        [types.STORE_SET_CHOOSE_TOOLS]: (state, tool) => {
            state.chosenTool = tool
        }
    },
    actions
}

export default tools
