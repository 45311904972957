import { getSiteConfig, getListMenus } from "../../../api/site-config"
import * as types from "../../types"
export default {
    async [types.GET_SITE]({ commit }, payload) {
        try {
            const data = await getSiteConfig(payload)
            document.title = data.site && data.site.site_name ? data.site.site_name : "Tools"
            if (data.site) {
                // eslint-disable-next-line no-undef
                $("#favicon").attr("href", data.site.favicon ? data.site.favicon : "assets/images/favicon.png")
                $("#keywords").attr("content", data.site.site_keyword ? data.site.site_keyword : "Dịch Vụ Facebook")
                $("#og-image").attr("content", data.site.logo ? data.site.logo : "assets/images/favicon.png")
                $("#og-title").attr("content", data.site.site_name ? data.site.site_name : "Dịch Vụ Facebook")
                $("#og-description").attr(
                    "content",
                    data.site.site_description ? data.site.site_description : "Dịch Vụ Facebook"
                )
                data.site.currency = data.site.currency ? data.site.currency : "Xu"
                data.site.logo = data.site.logo ? data.site.logo : "assets/images/logo-icon.png"
                let checkErrorConfig = data.site.config
                let config = null
                try {
                    if (
                        checkErrorConfig.includes('""') ||
                        checkErrorConfig.includes('""') ||
                        checkErrorConfig.includes("\\\\")
                    ) {
                        checkErrorConfig = checkErrorConfig.replace(/\\|"""|/g, "")
                        checkErrorConfig = checkErrorConfig.replace(/""""/g, "")
                        checkErrorConfig = checkErrorConfig.replace(/""{/g, "{")
                        checkErrorConfig = checkErrorConfig.replace(/}""/g, "}")
                    }
                    // eslint-disable-next-line no-empty
                } catch (error) {}
                try {
                    config = data.site && checkErrorConfig ? JSON.parse(checkErrorConfig) : {}
                } catch (error) {
                    checkErrorConfig = checkErrorConfig.match("{.+}")
                    checkErrorConfig = checkErrorConfig[0]
                    try {
                        config = data.site && checkErrorConfig ? JSON.parse(checkErrorConfig) : {}
                    } catch (error) {
                        config = data.site && data.site.config ? JSON.parse(data.site.config) : {}
                    }
                }
                data.site.config = Array.isArray(config) ? {} : config
                data.site.theme = config.theme ? config.theme : "skin-blue"
            }
            commit(types.STORE_SET_SITE_ID, data.site)
            return data
        } catch (e) {
            throw e
        }
    },
    async [types.GET_MENU_OUT_SITE]({ commit }) {
        try {
            const data = await getListMenus()
            if (data.data) {
                commit(types.STORE_SET_MENU_OUT_SITE, data.data)
            }
            return data
        } catch (e) {
            throw e
        }
    }
}
